<template>
  <div class="container">
    <div
      v-if="fullPractice"
      @click="backToEditPractice"
      class="leftQuestionCountTip"
    >
      <i class="fas fa-caret-left" />
      <span style="margin-left: 12px">
        {{ $t("practice.form.count")
        }}{{ fullPractice.compose_practice_practices.length }}
      </span>
    </div>
    <PracticeBreakTimeDialog
      v-if="currentPractice"
      :practice="currentPractice"
      @toggleVisiblePracticeBreakTimeDialog="
        toggleVisiblePracticeBreakTimeDialog
      "
      @submitPracticeBreakTime="addPractices"
      :visiblePracticeBreakTimeDialog="visiblePracticeBreakTimeDialog"
    />
    <Breadcrumb />
    <h1>{{ this.pageTitle }}</h1>
    <el-alert v-if="fullPractice" type="success" :closable="false">
      <div style="font-size: 16px">
        {{ $t("practice.form.addPracticeToFullPractice1")
        }}{{ fullPractice.exam.title
        }}{{ $t("practice.form.addPracticeToFullPractice2") }}<br />
        {{ $t("practice.form.alreadyAddPractice1")
        }}<span style="color:red; font-size: 20px">{{
          fullPractice.compose_practice_practices.length
        }}</span
        >{{ $t("practice.form.alreadyAddPractice2") }}
      </div>
    </el-alert>
    <el-table :data="practices" style="width: 100%;">
      <el-table-column label="Test">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'PracticeResults',
              params: { practiceId: scope.row.id }
            }"
          >
            {{ scope.row.exam.title }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Teacher(s)">
        <template slot-scope="scope">
          <el-tooltip
            :content="
              scope.row
                .getEditors()
                .map(teacher => teacher.name)
                .join(', ')
            "
            placement="top"
            popper-class="tooltipColor"
          >
            <span class="action-icon">
              {{ scope.row.getEditors().length }}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Questions">
        <template slot-scope="scope">
          <span>{{ scope.row.questions_count }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Time" width="80">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.has_timing
                ? `${scope.row.timing_minutes}:${scope.row.timing_seconds}`
                : "--"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Deadline">
        <template slot-scope="scope">
          <span>
            {{ scope.row.has_expire ? `${scope.row.expire_end}` : "--" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button
            key="delete"
            v-if="isExitPractice(scope.row.id)"
            type="danger"
            size="mini"
            @click="removePractice(scope.row.id)"
          >
            <i class="fas fa-trash-alt" />
            {{ $t("practice.form.removePractice") }}
          </el-button>
          <el-button
            key="add"
            v-else
            type="primary"
            size="mini"
            @click="toggleVisiblePracticeBreakTimeDialog(true, scope.row)"
          >
            <i class="fas fa-plus" /> {{ $t("practice.form.addPractice") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import PracticeBreakTimeDialog from "@/components/practices/PracticeBreakTimeDialog";
import practiceApi from "@/apis/practices";
import fullPracticesApi from "@/apis/fullPractices";
import Practice from "@/views/practices/Practice";
import FullPractice from "@/views/practices/FullPractice";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb, PracticeBreakTimeDialog },
  mixins: [roleMixin],
  data() {
    return {
      fullPractice: null,
      practices: [],
      currentPractice: null,
      visiblePracticeBreakTimeDialog: false
    };
  },
  computed: {
    pageTitle() {
      return `${this.$t("pageTitle.AddSectionsToFullPractice")}`;
    }
  },
  created() {
    this.fetchFullPractice();
    this.fetchPractices();
  },
  methods: {
    async fetchFullPractice() {
      const { compose_practice } = await fullPracticesApi.fetchFullPractice(
        this.$route.params.fullPracticeId
      );
      this.fullPractice = new FullPractice(compose_practice);
    },
    async fetchPractices() {
      const { practices } = await practiceApi.fetchPractices();
      this.practices = practices.map(practice => new Practice(practice));
    },
    isExitPractice(practiceId) {
      return this.fullPractice.compose_practice_practices.some(
        ({ practice_id }) => practice_id === practiceId
      );
    },
    toggleVisiblePracticeBreakTimeDialog(type, section) {
      this.currentPractice = type ? section : null;
      this.visiblePracticeBreakTimeDialog = type;
    },
    async removePractice(practiceId) {
      try {
        const { id } = this.fullPractice.compose_practice_practices.find(
          ({ practice_id }) => practice_id === practiceId
        );
        await fullPracticesApi.removePractices(id);
        await this.fetchFullPractice();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async addPractices(breakSeconds) {
      try {
        const practice = await fullPracticesApi.addPractices(
          this.$route.params.fullPracticeId,
          {
            practice_id: this.currentPractice.id,
            break_seconds: breakSeconds,
            scoring_type: null
          }
        );
        await this.fetchFullPractice();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    backToEditPractice() {
      this.$router.push({
        name: "EditFullPractices",
        params: {
          fullPracticeId: this.$route.params.fullPracticeId
        }
      });
    }
  }
};
</script>

<style>
.leftQuestionCountTip {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: #42a16a;
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
