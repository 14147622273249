import { EnumPracticeTakeStatuses } from "@/enums";

class FullPractice {
  constructor(fullPractice = {}) {
    this.id = fullPractice.id || null;
    this.exam = fullPractice.exam || { title: "Test Name" };
    this.compose_practice_practices = fullPractice.compose_practice_practices;
    this.has_timing = fullPractice.has_timing || 0;
    this.timing_minutes = fullPractice.timing_minutes
      ? String(fullPractice.timing_minutes).padStart(2, "0")
      : "00";
    this.timing_seconds = fullPractice.timing_seconds
      ? String(fullPractice.timing_seconds).padStart(2, "0")
      : "00";
    this.has_expire = fullPractice.has_expire || 0;
    this.expire_start = fullPractice.expire_start || null;
    this.expire_end = fullPractice.expire_end || null;
    // COMPLETION_ONLY, CORRECTNESS, CORRECTNESS_AND_EXPLANATION
    this.show = fullPractice.show || "CORRECTNESS";
    this.is_public_for_edit = Boolean(fullPractice.is_public_for_edit) || false;
    this.take_status = fullPractice.take_status || EnumPracticeTakeStatuses.private;
    this.permissions = fullPractice.permissions || [];
    this.practices_count = fullPractice.practices_count || 0;
    this.user_exams = fullPractice.user_exams || [];
    this.template_type = fullPractice.template_type || "ORIGINAL";
    this.scoring_type = fullPractice.scoring_type || "DEFAULT";
    this.scoring_id = fullPractice.scoring_id || 0;
    this.taken_practice_users = fullPractice.taken_practice_users || [];
  }

  getEditors() {
    return this.getPermissionTypeOf("edit", "App\\Entities\\User");
  }

  getTakeStudents() {
    return this.getPermissionTypeOf("take", "App\\Entities\\User");
  }

  getTakeSessionClass() {
    return this.getPermissionTypeOf("take", "App\\Entities\\SessionClass");
  }

  getPermissionTypeOf(permissionType, modelType) {
    const targetPermissions = this.permissions.filter(
      permission =>
        permission.permission_type === permissionType &&
        permission.model_type === modelType
    );
    return targetPermissions.map(permission => permission.model);
  }
}

export default FullPractice;
