export default {
  Present: 1,
  Late: 2,
  Excused: 4,
  Absent: 3
};

export const EnumShortAttendanceStatuses = {
  1: "P",
  2: "L",
  3: "A",
  4: "E",
  P: 1,
  L: 2,
  A: 3,
  E: 4
};
