export default {
  1: "Email",
  2: "Phone",
  3: "Line",
  4: "Skype",
  5: "Wechat",
  6: "Facetime"
};

export const ContactColumnKeyMap = {
  1: "email",
  2: "phone",
  3: "line_id",
  4: "skype_id",
  5: "wechat_id",
  6: "phone"
};
