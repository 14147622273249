import { EnumPracticeTakeStatuses } from "@/enums";

class Practice {
  constructor(practice = {}) {
    this.id = practice.id || null;
    this.exam = practice.exam || { title: "Test Name", questions: [] };
    this.has_timing = practice.has_timing || 0;
    this.timing_minutes =
      String(practice.timing_minutes).padStart(2, "0") || "00";
    this.timing_seconds =
      String(practice.timing_seconds).padStart(2, "0") || "00";
    this.has_expire = practice.has_expire || 0;
    this.expire_start = practice.expire_start || null;
    this.expire_end = practice.expire_end || null;
    // COMPLETION_ONLY, CORRECTNESS, CORRECTNESS_AND_EXPLANATION
    this.show = practice.show || "CORRECTNESS";
    this.is_public_for_edit = Boolean(practice.is_public_for_edit) || false;
    this.take_status = practice.take_status || EnumPracticeTakeStatuses.private;
    this.permissions = practice.permissions || [];
    this.questions_count = practice.questions_count || 0;
    this.user_exams = practice.user_exams || [];
    this.template_type = practice.template_type || "ORIGINAL";
    this.taken_practice_users = practice.taken_practice_users || [];
  }

  getLimitTime() {
    return this.has_timing
      ? this.timing_minutes * 60 + Number(this.timing_seconds)
      : Number.MAX_SAFE_INTEGER;
  }

  getEditors() {
    return this.getPermissionTypeOf("edit", "App\\Entities\\User");
  }

  getTakeStudents() {
    return this.getPermissionTypeOf("take", "App\\Entities\\User");
  }

  getTakeSessionClass() {
    return this.getPermissionTypeOf("take", "App\\Entities\\SessionClass");
  }

  getPermissionTypeOf(permissionType, modelType) {
    const targetPermissions = this.permissions.filter(
      permission =>
        permission.permission_type === permissionType &&
        permission.model_type === modelType
    );
    return targetPermissions.map(permission => permission.model);
  }
}

export default Practice;
