import ApiService from "@/common/api.service";

export default {
  fetchPractices() {
    return ApiService.get("practices");
  },
  fetchPractice(practiceId) {
    return ApiService.get(`practice/${practiceId}`);
  },
  createPractice(practice) {
    return ApiService.post("practice", practice);
  },
  updatePractice(practice) {
    return ApiService.patch(`practice/${practice.id}`, practice);
  },
  deletePractice(practiceId) {
    return ApiService.delete(`practice/${practiceId}`);
  },
  copyPractice(practiceId, copyOption) {
    return ApiService.post(`practice/${practiceId}/copy`, copyOption);
  },
  savePracticePermission(practiceId, permission) {
    return ApiService.post(`practice-permission/${practiceId}`, permission);
  },
  addQuestions(practiceId, question) {
    return ApiService.post(`practice-question/${practiceId}`, question);
  },
  removeQuestions(examQuestionId) {
    return ApiService.delete(`practice-question/${examQuestionId}`);
  },
  refreshQuestionsOrder(questions) {
    return ApiService.patch("practice/refresh", questions);
  },
  takePracticeExam(practiceId, userExam) {
    return ApiService.query(`practice/${practiceId}/take`, userExam);
  },
  postPracticeAnswer(userExamId, answer) {
    return ApiService.post(`practice/take/${userExamId}`, answer);
  },
  checkAlreadyTakenExam(practiceId) {
    return ApiService.get(`practice/${practiceId}/taken`);
  },
  fetchPracticeExamResult(userExamId) {
    return ApiService.get(`practice/result/${userExamId}`);
  },
  fetchPracticeResults(practiceId, session_class_id) {
    return ApiService.query(`practice/${practiceId}/results`, {
      session_class_id
    });
  },
  deletePracticeUserExam(userExamId) {
    return ApiService.delete(`practice/result/${userExamId}`);
  }
};
