<template>
  <el-dialog
    width="600px"
    :title="
      `${practice.exam.full_name} ${$t('practice.form.breakTimeAfterSection')}`
    "
    :visible.sync="visiblePracticeBreakTimeDialog"
    :before-close="
      () => {
        $emit('toggleVisiblePracticeBreakTimeDialog', false);
      }
    "
    center
  >
    <el-row>
      <el-col>
        <el-input style="width: 50%" placeholder="Minutes" v-model="minutes">
          <template slot="prepend">
            <i class="el-icon-alarm-clock" />
          </template>
          <template slot="append">{{ $t("practice.form.min") }}</template>
        </el-input>
        <el-input style="width: 50%" placeholder="Seconds" v-model="seconds">
          <template slot="append">{{ $t("practice.form.sec") }}</template>
        </el-input>
      </el-col>
    </el-row>
    <hr class="separate-line" />
    <div style="text-align: right">
      <el-button
        @click="
          () => {
            $emit('toggleVisiblePracticeBreakTimeDialog', false);
          }
        "
      >
        {{ $t("button.cancel") }}
      </el-button>
      <el-button
        type="primary"
        @click="
          () => {
            $emit('submitPracticeBreakTime', breakSeconds);
            $emit('toggleVisiblePracticeBreakTimeDialog', false);
          }
        "
      >
        {{ $t("button.save") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";

export default {
  props: {
    practice: {
      type: Object,
      default: () => ({})
    },
    defaultMinutes: {
      type: Number,
      default: 0
    },
    defaultSeconds: {
      type: Number,
      default: 0
    },
    visiblePracticeBreakTimeDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    breakSeconds() {
      return (this.minutes || 0) * 60 + (this.seconds || 0);
    }
  },
  created() {
    this.minutes = this.defaultMinutes;
    this.seconds = this.defaultSeconds;
  },
  data() {
    return {
      minutes: 0,
      seconds: 0
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>
