import ApiService from "@/common/api.service";

export default {
  fetchFullPractices() {
    return ApiService.get("compose-practices");
  },
  fetchFullPractice(fullPracticeId) {
    return ApiService.get(`compose-practice/${fullPracticeId}`);
  },
  createFullPractice(fullPractice) {
    return ApiService.post("compose-practice", fullPractice);
  },
  updateFullPractice(fullPractice) {
    return ApiService.patch(
      `compose-practice/${fullPractice.id}`,
      fullPractice
    );
  },
  deleteFullPractice(fullPracticeId) {
    return ApiService.delete(`compose-practice/${fullPracticeId}`);
  },
  addPractices(fullPracticeId, practice) {
    return ApiService.post(
      `compose-practice-practice/${fullPracticeId}`,
      practice
    );
  },
  updatePractices(relatedPracticeId, practice) {
    return ApiService.patch(
      `compose-practice-practice/${relatedPracticeId}`,
      practice
    );
  },
  removePractices(practiceId) {
    return ApiService.delete(`compose-practice-practice/${practiceId}`);
  },
  removeAllPractices(fullPracticeId) {
    return ApiService.delete(`compose-practice-practices/${fullPracticeId}`);
  },
  refreshPracticesOrder(practices) {
    return ApiService.patch("compose-practice/refresh", {
      compose_practice_practices: practices
    });
  },
  takeFullPracticeExam(fullPracticeId, userExam) {
    return ApiService.query(
      `compose-practice/${fullPracticeId}/take`,
      userExam
    );
  },
  checkAlreadyTakenExam(practiceId) {
    return ApiService.get(`compose-practice/${practiceId}/taken`);
  },
  postFullPracticeAnswer(userExamId, answer) {
    return ApiService.post(`compose-practice/take/${userExamId}`, answer);
  },
  updateFullPracticeExamStatus(userExamId, otherStatus) {
    return ApiService.patch(`compose-practice/${userExamId}/status`, {
      other_status: otherStatus
    });
  },
  fetchFullPracticeExamResult(userExamId) {
    return ApiService.get(`compose-practice/result/${userExamId}`);
  },
  saveFullPracticePermission(fullPracticeId, permission) {
    return ApiService.post(
      `compose-practice-permission/${fullPracticeId}`,
      permission
    );
  },
  fetchFullPracticeResults(fullPracticeId, session_class_id) {
    return ApiService.query(`compose-practice/${fullPracticeId}/results`, {
      session_class_id
    });
  },
  deleteFullPracticeUserExam(userExamId) {
    return ApiService.delete(`compose-practice/result/${userExamId}`);
  }
};
