var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.fullPractice)?_c('div',{staticClass:"leftQuestionCountTip",on:{"click":_vm.backToEditPractice}},[_c('i',{staticClass:"fas fa-caret-left"}),_c('span',{staticStyle:{"margin-left":"12px"}},[_vm._v(" "+_vm._s(_vm.$t("practice.form.count"))+_vm._s(_vm.fullPractice.compose_practice_practices.length)+" ")])]):_vm._e(),(_vm.currentPractice)?_c('PracticeBreakTimeDialog',{attrs:{"practice":_vm.currentPractice,"visiblePracticeBreakTimeDialog":_vm.visiblePracticeBreakTimeDialog},on:{"toggleVisiblePracticeBreakTimeDialog":_vm.toggleVisiblePracticeBreakTimeDialog,"submitPracticeBreakTime":_vm.addPractices}}):_vm._e(),_c('Breadcrumb'),_c('h1',[_vm._v(_vm._s(this.pageTitle))]),(_vm.fullPractice)?_c('el-alert',{attrs:{"type":"success","closable":false}},[_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("practice.form.addPracticeToFullPractice1"))+_vm._s(_vm.fullPractice.exam.title)+_vm._s(_vm.$t("practice.form.addPracticeToFullPractice2"))),_c('br'),_vm._v(" "+_vm._s(_vm.$t("practice.form.alreadyAddPractice1"))),_c('span',{staticStyle:{"color":"red","font-size":"20px"}},[_vm._v(_vm._s(_vm.fullPractice.compose_practice_practices.length))]),_vm._v(_vm._s(_vm.$t("practice.form.alreadyAddPractice2"))+" ")])]):_vm._e(),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.practices}},[_c('el-table-column',{attrs:{"label":"Test"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'PracticeResults',
            params: { practiceId: scope.row.id }
          }}},[_vm._v(" "+_vm._s(scope.row.exam.title)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Teacher(s)"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"content":scope.row
              .getEditors()
              .map(teacher => teacher.name)
              .join(', '),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{staticClass:"action-icon"},[_vm._v(" "+_vm._s(scope.row.getEditors().length)+" ")])])]}}])}),_c('el-table-column',{attrs:{"label":"Questions"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.questions_count))])]}}])}),_c('el-table-column',{attrs:{"label":"Time","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.has_timing ? `${scope.row.timing_minutes}:${scope.row.timing_seconds}` : "--")+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Deadline"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(" "+_vm._s(scope.row.has_expire ? `${scope.row.expire_end}` : "--")+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Action"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isExitPractice(scope.row.id))?_c('el-button',{key:"delete",attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.removePractice(scope.row.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"}),_vm._v(" "+_vm._s(_vm.$t("practice.form.removePractice"))+" ")]):_c('el-button',{key:"add",attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.toggleVisiblePracticeBreakTimeDialog(true, scope.row)}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("practice.form.addPractice"))+" ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }