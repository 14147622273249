var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"width":"600px","title":`${_vm.practice.exam.full_name} ${_vm.$t('practice.form.breakTimeAfterSection')}`,"visible":_vm.visiblePracticeBreakTimeDialog,"before-close":() => {
      _vm.$emit('toggleVisiblePracticeBreakTimeDialog', false);
    },"center":""},on:{"update:visible":function($event){_vm.visiblePracticeBreakTimeDialog=$event}}},[_c('el-row',[_c('el-col',[_c('el-input',{staticStyle:{"width":"50%"},attrs:{"placeholder":"Minutes"},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"el-icon-alarm-clock"})]),_c('template',{slot:"append"},[_vm._v(_vm._s(_vm.$t("practice.form.min")))])],2),_c('el-input',{staticStyle:{"width":"50%"},attrs:{"placeholder":"Seconds"},model:{value:(_vm.seconds),callback:function ($$v) {_vm.seconds=$$v},expression:"seconds"}},[_c('template',{slot:"append"},[_vm._v(_vm._s(_vm.$t("practice.form.sec")))])],2)],1)],1),_c('hr',{staticClass:"separate-line"}),_c('div',{staticStyle:{"text-align":"right"}},[_c('el-button',{on:{"click":() => {
          _vm.$emit('toggleVisiblePracticeBreakTimeDialog', false);
        }}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":() => {
          _vm.$emit('submitPracticeBreakTime', _vm.breakSeconds);
          _vm.$emit('toggleVisiblePracticeBreakTimeDialog', false);
        }}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }